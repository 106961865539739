// 模块管理
import { Request } from '@/http/request'

// 模块集合列表 
export function appQuery (parameter: any) {
  return Request.axiosInstance.post('/web/app/query', parameter)
}
// 添加模块
export function addApp (parameter: any) {
  return Request.axiosInstance.post('/web/app/add', parameter)
}
// 删除模块
export function deleteApp (parameter: any) {
  return Request.axiosInstance.post('/web/app/delete', parameter)
}
// 修改模块
export function editApp (parameter: any) {
  return Request.axiosInstance.post('/web/app/edit', parameter)
}
// 模板列表
export function appList (parameter: any) {
  return Request.axiosInstance.post('/web/app/list', parameter)
}
// 获取模块详情 
export function getAppData (parameter: any) {
  return Request.axiosInstance.post('/web/app/get', parameter)
}

export default { appQuery, addApp, deleteApp, editApp, appList, getAppData }