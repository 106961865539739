<template>
  <!-- 资源管理 -->
  <div class="user">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
            >
              <!-- 筛选项 -->
              <el-row>
                <el-col :span="20">
                  <el-row :gutter="12">
                    <el-col :span="5">
                      <el-form-item label="名&emsp;&emsp;称：" prop="">
                        <el-input v-model="searchForm.packetName" size="mini" placeholder="请输入名称"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="模&emsp;&emsp;块：" prop="">
                        <el-select v-model="searchForm.type" placeholder="请选择" size="mini">
                        <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="5">
                      <el-form-item label="分&ensp;钟&ensp;数：" prop="">
                        <el-input v-model="searchForm.minutes" size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="使用次数：" prop="">
                        <el-input v-model="searchForm.num" size="mini"></el-input>
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="5">
                      <el-form-item label="状&emsp;&emsp;态：" prop="">
                        <el-select
                          v-model="searchForm.status"
                          placeholder="请选择"
                          style="width: 100%"
                          size="mini"
                          clearable
                        >
                          <el-option
                            v-for="(item, index) in ['正常','禁用']"
                            :key="index"
                            :label="item"
                            :value="index"
                            size="mini"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="5">
                      <el-form-item label="添加时间：" prop="">
                        <el-input v-model="searchForm.createTime" size="mini"></el-input>
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                </el-col>
                <el-col :span="4">
                  <div class="title-buttom">
                    <el-button class="cancel" size="mini" @click="searchTapReset" round>重置</el-button>
                    <el-button type="primary" size="mini" class="confirmAdd" @click="searchListFun" round>查询</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>资源列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <!-- <el-tooltip content="列表刷新" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="searchTapReset()"
              circle
            ></el-button>
          </el-tooltip> -->
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x:auto">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%;overflow:auto"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          >
            <el-table-column label="名称">
              <template #default="scope">{{
                scope.row.packetName == null ? "-" : scope.row.packetName
              }}</template>
            </el-table-column>
            <el-table-column label="模块">
              <template #default="scope">
                <el-tag>{{ $filter.appIdToModule(scope.row.packet.type,ppJson) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="分钟数(剩余/总数)">
              <template #default="scope">{{
                scope.row.packet.type != 3?scope.row.remainMinutes+'/'+scope.row.minutes+'(分钟)':'-'
              }}</template>
            </el-table-column>
            <el-table-column label="次数(剩余/总数)">
              <template #default="scope">{{
                scope.row.packet.type == 3?scope.row.remainNums+'/'+scope.row.nums+'(次)':'-'
              }}</template>
            </el-table-column>
            <el-table-column label="状态">
              <template #default="scope">
                <span :class="scope.row.status == 0?'pass':'unpass'">{{ scope.row.status == null ? "-" : scope.row.status===0?'正常':'禁用' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始-到期时间" width="260px">
              <template #default="scope">
                <p>开始时间：{{scope.row.createTime == null ? "-" : scope.row.createTime}}</p>
                <p>到期时间：{{scope.row.expireTime == null ? "-" : scope.row.expireTime}}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗→添加/修改 -->
    <div class="pop-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="item_form"
        ref="item_form"
      >
        <el-dialog
          :title="addOrModify==1?'套餐添加':'套餐修改'"
          v-model="centerItemVisible"
          width="50%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="5">
                <el-form-item label="名称：">
                  <el-input v-model="item_form.packetName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="描述：">
                  <el-input v-model="item_form.remark"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="价格：">
                  <el-input v-model="item_form.price"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="数量：">
                  <el-input v-model="item_form.stock"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="有效天数：">
                  <el-input v-model="item_form.days"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="模块：">
                  <el-select v-model="item_form.type" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="总分钟数：">
                  <el-input v-model="item_form.minutes"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="总使用次数：">
                  <el-input v-model="item_form.num"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="状态：">
                  <el-select
                    v-model="item_form.status"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['正常', '禁用']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerItemVisible = false"
                round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="itemSubmit(addOrModify)" round
                >{{addOrModify==1?'添加':'修改'}}</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>
import resources from "@/api/web/resources"
import packet from "@/api/web/packet"
import app from '@/api/web/app'
import { IconFont } from "@/utils/iconfont";
export default {
  name:"User",
  components:{
    IconFont
  },
  data(){
    return{
      tableData: [], //数据列表
      centerItemVisible: false, // 弹窗--添加及修改
      activeNames: [], // 折叠面板
      tableHeight: "", // 表格高度
      currentPage: 1, // 分页
      total: 0,     // 总数据条数
      loading: true, // loding 状态启用
      addOrModify:'1',  // 添加或修改 (1:添加 2:修改)
      options: [],//模块列表
      searchForm:{  // 搜索项
        packetName:'',    // 资源包名称
        remark:'',  // 资源包描述
        price:'',   // 价格
        stock:'',   // 资源包数量
        days:'',    // 有效天数
        type:'',    // 资源包类型 (1:隐私号 2:语音质检 3:黑名单)
        minutes:'', // 包含分钟数
        num:'',     // 包含使用次数
        status:'',  // 资源包状态
        createTime:'',  // 添加时间
        page:1,
        pageSize:10,
      },
      item_form:{  // 搜索项
        packetName:'',    // 资源包名称
        remark:'',  // 资源包描述
        price:'',   // 价格
        stock:'',   // 资源包数量
        days:'',    // 有效天数
        type:'',    // 资源包类型
        minutes:'', // 包含分钟数
        num:'',     // 包含使用次数
        status:'',  // 资源包状态
        createTime:'',  // 添加时间
      },
      isId:'',
      ppJson:{},
    }
  },
  methods:{
    getLowList(){
        app.appQuery({}).then((res) => {
            this.options = res.data
            this.options.splice(0,1)
            let newJson = {}
            res.data.forEach(item => {
                newJson[item.id] = item.name
            });
            this.ppJson = newJson
        })
    },
    // 列表添加--提交
    itemSubmit(){
      if(this.addOrModify == 1){
        packet.editUser({...this.item_form}).then(res=>{
          if(res.code===200){
            this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success'
            });
            this.centerItemVisible = false;
            this.getListData();
          }
        })
      }
      if(this.addOrModify == 2){
        packet.addUser({...this.item_form}).then(res=>{
          if(res.code===200){
            this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
            });
            this.centerItemVisible = false;
            this.getListData();
          }
        })
      }
    },
    // 列表添加--点击
    addToItem(row){
      console.log(row)
      if(row == undefined){
        this.addOrModify = 1
      }else{
        this.addOrModify = 2
         this.isId = row.id
      }
      this.centerItemVisible = true;
    },
    // 条件筛选--重置
    searchTapReset(){
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      };
      this.getListData();
    },
    // 条件筛选--搜索
    searchListFun(){
      this.getListData();
    },
    // 分页
    handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.searchForm.pageSize = val
        this.getDataList()
    },
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.searchForm.page = val
        this.currentPage =val
        this.getDataList()
    },
    // 获取列表数据
    getListData(){
      this.loading = true;
      resources.listUser({...this.searchForm}).then(res=>{
        if(res.code===200){
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        }
      })
    }
  },
  mounted() {
    this.getListData();
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
    this.getLowList()
  },
}
</script>
<style lang="scss" scoped>
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.pass{
    color: #67C23A;
}
.unpass{
    color: #F56C6C;
}
</style>