import { Request } from "@/http/request";

// 增添套餐
export function addUser (parameter: any){
  return Request.axiosInstance.post('/open/packet/add',parameter)
}

// 套餐详情
export function getUser (parameter: any){
  return Request.axiosInstance.post('/open/packet/get',parameter)
}

// 删除套餐
export function deleteUser (parameter: any){
  return Request.axiosInstance.post('/open/packet/delete',parameter)
}

// 修改套餐
export function editUser (parameter: any){
  return Request.axiosInstance.post('/open/packet/edit',parameter)
}

// 套餐列表
export function listUser (parameter: any){
  return Request.axiosInstance.post('/open/packet/list', parameter)
}

export default { addUser,getUser,deleteUser,editUser,listUser }