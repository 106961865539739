import { Request } from "@/http/request";

// 用户资源包增添
export function addUser (parameter: any){
  return Request.axiosInstance.post('/open/user-packet/add',parameter)
}

// 用户购买资源包
export function shopUser (parameter: any){
  return Request.axiosInstance.post('/open/user-packet/buy',parameter)
}

// 用户资源包删除
export function deleteUser (parameter: any){
  return Request.axiosInstance.post('/open/user-paceet/delete',parameter)
}

// 用户资源包修改
export function editUser (parameter: any){
  return Request.axiosInstance.post('/open/user-packet/edit',parameter)
}

// 用户资源包列表
export function listUser (parameter: any){
  return Request.axiosInstance.post('/open/user-packet/list', parameter)
}

export default { addUser,shopUser,deleteUser,editUser,listUser }